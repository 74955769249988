
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link , useLocation} from "react-router-dom";
import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import Navigation from  "./Navigation";
import Animation from "./components/Animation"
import AppearAnimation from "./components/appearAnimation";
import { AnimatePresence } from "framer-motion"

function App() {
  //const location = useLocation();
  return (
    <div>
      <Navigation />
    <Router >
    <Routes>
        <Route exact path="/" element={<Animation />} />
        {/* <Route exact path="/scrollAnimations" element={<ScrollAnimations />} />
        <Route exact path="/MotionValueAnimation" element={<MotionValueAnimation />} />
        <Route exact path="/MotionValueAnimation" element={<MotionValueAnimation />} />
        <Route exact path="/HorizontalScroll1" element={<HorizontalScroll1 />} /> */}
        <Route exact path="/AppearAnimation" element={<AppearAnimation />} />
        {/* <Route exact path="/path3" element={<component3 />} /> */}
    </Routes>
  </Router>
  </div>
  );
}

export default App;
