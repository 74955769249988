import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from 'reactstrap';
import { motion, useScroll as UseScroll} from "framer-motion";

function Navigation(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const { scrollYProgress } = UseScroll();
  return (
    <>
   
    <div style={{width:"100%", margin:"auto", padding:0, height:"100px"}} >
      <Navbar color="info" light expand="md">
          <NavbarBrand href="/" className='text-bold'>Ibluesys</NavbarBrand>
          <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
          <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                  {/* <NavItem>
                      <NavLink href="scrollAnimations">Scroll Animations</NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink href="MotionValueAnimation">Motion Animation</NavLink>
                  </NavItem>*/}
                  <NavItem>
                      <NavLink href="/">Image Animation</NavLink>
                  </NavItem> 
                  <NavItem>
                      <NavLink href="AppearAnimation">Appear Animation</NavLink>
                  </NavItem>
              </Nav>
          </Collapse>
      </Navbar>
  </div >
   <div style={{backgroundColor:"#ccc", width:"100%", position:"sticky", top: 0, zIndex:500}}>
        <div style={{width:"55%", margin:"auto", padding:10,}} >
            <h5>Advertising Features @info</h5>
        </div>
        <motion.div style={{height:"5px", backgroundColor:"maroon", scaleX: scrollYProgress}} className='progress-bar'></motion.div>
   </div>
   
   </>
  );
}

export default Navigation;