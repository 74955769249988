import logo from '../logo.svg';
import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { motion, AnimatePresence, useScroll } from "framer-motion"

import img from "../assets/image05.jpeg";
import img2 from "../assets/image06.jpg";
import img3 from "../assets/image07.jpg";
import img4 from "../assets/image08.jpg";
import img5 from "../assets/image09.jpg";
import img6 from "../assets/image10.jpg";

const varientOne = {
  hidden:{opacity:0, x:"-100vw"},
  visible:{
    opacity:1, 
    x:0,
    transition:{
      type:"spring", 
      // delay: 0.7,
			// duration: 0.2,
			when: 'beforeChildren',
      staggerChildren:0.5}
  },
  exit:{
    x:-300,
    transition:{
        duration:1
    }
  }
}
const varientTwo = {
  hidden:{opacity:0, x:-100},
  visible:{
    opacity:1, 
    x:0,
    transition:{type:"spring", duration:1, when: 'beforeChildren',
    staggerChildren:0.5}
  }
}
const varientHeader = {
  hidden:{opacity:0, y:-100},
  visible:{
    opacity:1, 
    y:0,
    transition:{type:"spring", duration:1}
  }
}
const varientSmall = {
  hidden:{opacity:0, y:-100},
  visible:{
    opacity:1, 
    y:0,
    transition:{type:"spring", duration:1}
  }
}
const varientText = {
  hidden:{opacity:0, y:-100},
  visible:{
    opacity:1, 
    y:0,
    transition:{type:"spring", duration:1}
  }
}
const varientImg = {
  hidden:{opacity:0, x:-100},
  visible:{
    opacity:1, 
    x:0,
    rotate: 360 ,
    transition:{type:"spring", duration:1}
  }
}
const varientButton = {
  hidden:{opacity:0, x:-100},
  visible:{
    opacity:1, 
    x:0,
    // transition:{type:"spring", duration:1}
  }
}
const Animations = ()=> {
  const { scrollYProgress } = useScroll();
  return (
    <AnimatePresence mode={"wait"}>
    <motion.div 
    variants={varientOne}
    initial="hidden"
    whileInView="visible"
    exit={{ x: -300, duration:1}}
    >
      
      <Container  className=''>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left", padding:10}}>
          <div className='d-flex align-items-center justify-content-start p-2 '>
          <motion.img src={img2}
          variants={varientImg}
          />
          <motion.img src={img3}
           variants={varientImg}
            />
          {/* <img src={img4} /> */}
          {/* <img src={img5} /> */}
          {/* <img src={img6} /> */}
          </div>
    
        </div>
        <motion.div className='col-6' 
        variants={varientTwo}
        >
        <motion.h1 className='text-primary'
        variants={varientHeader}
        >Wellness and Nature</motion.h1>
          <motion.small className='text-success'
          variants={varientSmall}
          >JAPAN IS FULL OF AWE-INSPIRING LANDSCAPES</motion.small>
          <motion.p
          variants={varientText}
          >metaphorical ‘seas’ of clouds settling among forested peaks; perfectly formed volcanic cones, iced with snow; and waterfalls plunging over dramatic cliffs, to name a few. Some are so awe-inspiring that they were viewed by ancient Japanese as sacred—a feeling that lingers to this day.</motion.p>
          <motion.div  variants={varientButton} className='d-flex justify-content-start' >
            <div style={{padding:"5px"}}>
            <motion.button className='btn bg-success text-white' 
           variants={varientButton}
            whileHover={{
              scale:1.1,
              boxShadow:"0px 0px 8px rgb(0,0,0)",
            }}
            >Scale me</motion.button>
            </div>
          <div style={{padding:"5px"}}>
            <motion.button className='btn bg-success text-white' 
           variants={varientButton}
            whileHover={{
              scale:1.1,
              boxShadow:"0px 0px 8px rgb(0,0,0)",
              rotate:360
            }}
            >Rotate me</motion.button>
            </div>
          <div style={{padding:"5px"}}>
            <motion.button className='btn bg-success text-white' 
           variants={varientButton}
            whileHover={{
              scale:5.1,
              boxShadow:"0px 0px 8px rgb(0,0,0)",
            }}
            >Zoom me</motion.button>
            </div>
          </motion.div>
        </motion.div>
      </div>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
        
        <motion.div
       className='d-flex align-items-center justify-content-between p-4' >
        <motion.div
        initial={{opacity:0, x:-700}}
        whileInView = {{ opacity:1, x: 1 }}
        viewport={1}
        transition={{ ease: "linear", duration:2 }} className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </motion.div>
        <motion.div
        initial={{opacity:0, x:700}}
        whileInView = {{ opacity:1, x: 0 }}
        transition={{ ease: "linear" }} className='col-6' style={{textAlign:"left"}}>
        <img src={img} />
        </motion.div>
      </motion.div>
      <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
        <div className='d-flex align-items-center justify-content-between p-4' >
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
        <div className='col-6' style={{textAlign:"left"}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
      </Container>
    </motion.div>
    </AnimatePresence>
  );
}

export default Animations;
