import React, { useEffect, useRef as UseRef , useState} from 'react';
import { motion, useScroll as UseScroll, useSpring as UseSpring, useTransform as UseTransform} from "framer-motion";
import image01 from "../assets/image01.jpg";
import image02 from "../assets/image02.jpg";
import image03 from "../assets/image03.jpg";
import image04 from "../assets/image04.jpg";
import image05 from "../assets/image05.jpg";


function useParallax(value, distance) {
    return UseTransform(value, [0, 1], [distance, -distance]);
  }
const ImagesCarousal =()=>{

    const ref = UseRef(null)
    let { scrollYProgress } = UseScroll({
        target: ref,
        //offset: ["start end", "start start"]
        offset: ["start end", "end start"]
      })
    const scaleX = UseSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
      });
    const moonY = useParallax(scrollYProgress, 600);
    const y = useParallax(scrollYProgress, 700);
    const s = useParallax(scrollYProgress, 300);
    const f = useParallax(scrollYProgress,150);

    const appearVaiant = {
        initial:{opacity: 0,  },
        visible:{
            opacity: 1,
           // transition:{ duration: 2 }
        }
    }
    const blosoomVarient1 = {
        initial:{opacity: 0, scale:1.5,   zIndex:"2", left:"-50%", top:"10%" },
        visible:{
            opacity: 1, scale: 0.7, zIndex:"2" ,left:"-28%", top:"10%",
            transition:{ duration: 0.9, delay:1}
        }
    }
    const toriigateVariant1 = {
        initial:{ x:"10vw",  opacity:0, left:"-10%", top:"60%", scale:1.5},
        visible:{
            opacity:1, 
            left:"-3%", top:"54%",scale:1,
            transition:{type:"spring", duration:3, delay:1}
        }
    }
    const moonVariant1 = {
        initial:{ x:"10vw",  opacity:0, scale:2.5, left:"-8%", top:"50%"},
        visible:{
            opacity:1, 
            scale:1,
            left:"8%", top:"45%",
            transition:{type:"easeIn", duration:2, delay:0.5}
        }
    }
    const mainTextVariant = {
        initial:{opacity: 0,},
        visible:{
            opacity: 1, 
            when: 'beforeChildren',
            staggerChildren:1
        }   
    }
    const parallaxhead = {
        initial:{opacity: 0, scale:3.5 },
        visible:{
            opacity: 1, scale: 1,
            transition:{ duration: 0.9, }
        }
    }
    const taglineVariant = {
        initial:{opacity:0, y:-400},
        visible:{
          opacity:1, 
          y:1,
          transition:{duration:1, delay:1}
        }
    }
    
    const blosoomVariant2 = {
        initial:{opacity: 0, scale:1.5,   },
        visible:{
            opacity: 1, scale: 1,
            transition:{ duration: 0.9, delay:1.5}
        }
    }
    const mountainVariant1 = {
        initial:{  scale:2.5, opacity:0},
        visible:{
            opacity:1, 
            scale:1,
            transition:{type:"spring", duration:3, delay:0.5}
        }
    }
    const geshVariant1 = {
        initial:{  scale:2.5, opacity:0},
        visible:{
            opacity:1, 
            scale:1,
            transition:{type:"spring", duration:3, delay:1}
        }
    }
    

    return(
        <div>
            <motion.div >
                <motion.div 
                 className='d-flex align-items-center justify-content-center' 
                    style={{ }}
                    variants={appearVaiant}
                    initial="initial"
                    whileInView="visible"
                    ref={ref}
                    >
                        <motion.img src={image01} 
                         style={{y, padding:"10px" }}
                        //variants={blosoomVarient1}
                      
                        />
                        <motion.img src={image02} 
                        style={{y:s, padding:"10px" }}
                       // variants={toriigateVariant1}
                        />
                    
                        <motion.img src={image03} 
                        // variants={moonVariant1}
                         style={{ y:f, padding:"10px" }}
                        />
                        <motion.img src={image04} 
                        // variants={moonVariant1}
                         style={{y:s, padding:"10px" }}
                        />
                        <motion.img src={image05} 
                        // variants={moonVariant1}
                         style={{padding:"10px" , y}}
                        />
                </motion.div>
            </motion.div>
           
   </div>
    )
}

export default ImagesCarousal;